var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import gql from "graphql-tag";
import { createEmptyPersonalInformationEntry, isValidPersonalInformationEntry, } from "../../../../data";
import SignInOrPersonalInformationForm from "../../../ui/SignInOrPersonalInformationForm.vue";
import PaymentForm from "../../../ui/payment/PaymentForm.vue";
import { billingFormDetailsToPaymentSource } from "../../../ui/payment/BillingDetailsData";
import Success from "./Success.vue";
import mixins from "vue-typed-mixins";
import PropertyPaymentPipeline from "./PropertyPaymentPipeline";
import { createStripe } from "../../../../utils/stripe";
import getAbsoluteUrl from "../../../../router/getAbsoluteUrl";
import WeChatPayCodeDialog from "../../../ui/WeChatPayCodeDialog.vue";
import PaymentMixin from "../../../ui/payment/PaymentMixin";
import { nl2br } from "../../../../utils/filters";
export default mixins(PaymentMixin).extend({
    components: {
        SignInOrPersonalInformationForm: SignInOrPersonalInformationForm,
        PaymentForm: PaymentForm,
        Success: Success,
        WeChatPayCodeDialog: WeChatPayCodeDialog,
    },
    apollo: {
        propertyListingPayment: {
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query PaymentQuery($id: ID!) {\n          propertyListingPayment(id: $id) {\n            id\n            propertyInfo\n            otherInfo\n            isPaid\n            contactFirstName\n            contactLastName\n            contactEmailAddress\n            listing {\n              id\n              slug\n              streetAddress\n            }\n            amountAud\n            amount {\n              amount\n              currency\n            }\n            enquiry {\n              id\n              fullName\n            }\n          }\n        }\n      "], ["\n        query PaymentQuery($id: ID!) {\n          propertyListingPayment(id: $id) {\n            id\n            propertyInfo\n            otherInfo\n            isPaid\n            contactFirstName\n            contactLastName\n            contactEmailAddress\n            listing {\n              id\n              slug\n              streetAddress\n            }\n            amountAud\n            amount {\n              amount\n              currency\n            }\n            enquiry {\n              id\n              fullName\n            }\n          }\n        }\n      "]))),
            variables: function () {
                return {
                    id: this.$route.params.id,
                };
            },
            result: function (result) {
                if (!result.data) {
                    return;
                }
                var payment = result.data.propertyListingPayment;
                if (payment.enquiry) {
                    this.step = "payment";
                    this.personalInformation = undefined;
                    return;
                }
                this.step = "personal";
                this.personalInformation = __assign(__assign({}, createEmptyPersonalInformationEntry()), { firstName: payment.contactFirstName, lastName: payment.contactLastName, emailAddress: payment.contactEmailAddress });
            },
        },
        viewerStudent: gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      {\n        viewerStudent {\n          id\n          ...PaymentForm_viewerStudent\n        }\n      }\n      ", "\n    "], ["\n      {\n        viewerStudent {\n          id\n          ...PaymentForm_viewerStudent\n        }\n      }\n      ", "\n    "])), PaymentForm.options.fragments.viewerStudent),
    },
    data: function () {
        return {
            step: "personal",
            personalInformation: createEmptyPersonalInformationEntry(),
        };
    },
    computed: {
        billingName: function () {
            if (!this.personalInformation) {
                if (!this.propertyListingPayment ||
                    !this.propertyListingPayment.enquiry) {
                    throw new Error("Invalid state");
                }
                return this.propertyListingPayment.enquiry.fullName;
            }
            return (this.personalInformation.firstName +
                " " +
                this.personalInformation.lastName);
        },
        paymentPipeline: function () {
            return new PropertyPaymentPipeline({
                $apollo: this.$apollo,
                $t: this.$t.bind(this),
                $router: this.$router,
                $store: this.$store,
                stripe: createStripe(this.stripePublishableKey),
            });
        },
        paymentStartInput: function () {
            if (!this.propertyListingPayment) {
                throw new Error("Invalid state, propertyListingPayment null");
            }
            if (this.personalInformation &&
                !isValidPersonalInformationEntry(this.personalInformation)) {
                throw new Error("Invalid personal information");
            }
            if (!this.billingFormDetails ||
                this.billingFormDetails.billingAddress === "same-as-shipping") {
                throw new Error("Invalid no billing form details");
            }
            var personalInformation = undefined;
            var purchasingAgent = undefined;
            var saveForNextTime = false;
            if (this.personalInformation) {
                var _a = this.personalInformation, enteredSaveForNextTime = _a.saveForNextTime, enteredPurchasingAgent = _a.purchasingAgent, restPersonalInformation = __rest(_a, ["saveForNextTime", "purchasingAgent"]);
                saveForNextTime = enteredSaveForNextTime;
                personalInformation = restPersonalInformation;
                purchasingAgent = enteredPurchasingAgent;
            }
            return {
                paymentId: this.propertyListingPayment.id,
                saveForNextTime: saveForNextTime,
                personalInformation: personalInformation,
                paymentSource: billingFormDetailsToPaymentSource(this.billingFormDetails),
                purchasingAgent: purchasingAgent,
                amountAud: this.propertyListingPayment.amountAud,
                amount: this.propertyListingPayment.amount,
                billingAddress: {
                    name: this.billingName,
                    address: this.billingFormDetails.billingAddress,
                },
                returnUrl: getAbsoluteUrl(this.$store, this.$router, "/accommodation/payment/".concat(this.propertyListingPayment.id, "/success")),
            };
        },
        propertyInfoHtml: function () {
            if (!this.propertyListingPayment) {
                return;
            }
            return nl2br(this.propertyListingPayment.propertyInfo);
        },
        otherInfoHtml: function () {
            if (!this.propertyListingPayment) {
                return;
            }
            return nl2br(this.propertyListingPayment.otherInfo);
        },
    },
    methods: {
        onPersonalInformationSubmit: function () {
            this.step = "payment";
        },
    },
    metaInfo: function () {
        if (!this.propertyListingPayment) {
            return {};
        }
        return {
            title: this.$t("Payment for {address}", {
                address: this.propertyListingPayment.listing.streetAddress,
            }).toString(),
        };
    },
});
var templateObject_1, templateObject_2;
